class AppConfigService {
  private hideWipFeatures = import.meta.env.VITE_HIDE_WIP_FEATURES?.toUpperCase() === "TRUE";

  public get isLocal(): boolean {
    return window.location.port !== "";
  }

  public get apiUrl(): string {
    return "";
  }

  public get shouldHideWipFeatures(): boolean {
    return this.hideWipFeatures;
  }

  public get canShowWipFeatures(): boolean {
    return !this.hideWipFeatures;
  }
}

export const AppConfig = new AppConfigService();
