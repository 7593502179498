import type { PathRouteProps } from "react-router-dom";

import HomePage from "../pages/home/HomePage";

import AccountPage from "~/pages/AccountPage";
import AdminPage from "~/pages/AdminPage";
import AnotherPage from "~/pages/AnotherPage";
import DemoPage from "~/pages/DemoPage";
interface Props extends PathRouteProps {
  bodyPadding?: number[];
  bgColor?: string;
}

export const routes: Array<Props> = [];

export const privateRoutes: Array<Props> = [
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/account",
    element: <AccountPage />
  },
  {
    path: "/another",
    element: <AnotherPage />
  },
  {
    path: "/demo",
    element: <DemoPage />
  },
  {
    path: "/admin",
    element: <AdminPage />
  }
];
