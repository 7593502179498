import { Flex, Heading } from "@chakra-ui/react";

import Meta from "~/layout/Meta";

const AnotherPage = () => {
  return (
    <>
      <Meta title="Another Page" />
      <Flex align={["top", "top"]}>
        <Heading as="h1">Another Page</Heading>
      </Flex>
    </>
  );
};

export default AnotherPage;
