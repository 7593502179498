import { Flex } from "@chakra-ui/react";
import { UserProfile } from "@clerk/clerk-react";

const AccountPage = () => {
  return (
    <Flex align={["top", "top"]}>
      <UserProfile />
    </Flex>
  );
};

export default AccountPage;
