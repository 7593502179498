import { useEffect, useState } from "react";

import { Box, Button, Heading, Spinner, VStack } from "@chakra-ui/react";
import { SignOutButton, useClerk, useUser } from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";

import { useAppState } from "~/hooks/useAppState";

type PrivateRouteProps = {
  children: React.ReactNode;
  redirectTo?: string;
};

const RequireAuth = ({ children, redirectTo = "/login" }: PrivateRouteProps) => {
  const clerk = useClerk();
  const user = useUser();
  const [hasCheckedUserInfo, setHasCheckedUserInfo] = useState(false);
  const { userInfo, setUserInfo, isAuthorized, setIsAuthorized } = useAppState(
    "userInfo",
    "setUserInfo",
    "isAuthorized",
    "setIsAuthorized"
  );

  useEffect(() => {
    if (!clerk.loaded) {
      return;
    }
    if (!user.isSignedIn) {
      setHasCheckedUserInfo(true);
      return;
    }

    if (user.isSignedIn && userInfo) {
      setHasCheckedUserInfo(true);

      console.log(user.user?.organizationMemberships);

      const firstOrganization = user.user?.organizationMemberships?.length
        ? user.user?.organizationMemberships[0].organization
        : null;
      if (firstOrganization) {
        clerk.setActive({ organization: firstOrganization });
      }
    }

    if (user.isSignedIn && !userInfo) {
      const fetchUserInfo = async () => {
        const response = await fetch(`/api/me`);

        if (response.status === 200) {
          const data = await response.json();
          setUserInfo(data);
          setHasCheckedUserInfo(true);
          setIsAuthorized(true);
        } else if (response.status === 403) {
          setUserInfo(undefined);
          setHasCheckedUserInfo(true);
          setIsAuthorized(false);
        }
      };
      fetchUserInfo();
    }
  }, [clerk.loaded, user.isSignedIn, userInfo]);

  const isAuthenticated = user.isSignedIn;

  if (!hasCheckedUserInfo) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="lg" />
      </Box>
    );
  }

  if (isAuthenticated && !isAuthorized && hasCheckedUserInfo) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100vw">
        <VStack>
          <Heading as="h1">Unauthorized</Heading>
          <Heading as="h2" size="sm">
            Sorry, {user.user?.primaryEmailAddress?.emailAddress} is not authorized to view this page
          </Heading>
          <Button variant="secondary" size="sm">
            <SignOutButton />
          </Button>
        </VStack>
      </Box>
    );
  }

  return isAuthenticated ? (children as React.ReactElement) : <Navigate to={redirectTo} />;
};

export default RequireAuth;
