import { useEffect } from "react";

import { Box, ChakraProvider } from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router } from "react-router-dom";

import Routings from "~/router/Routings";
import { identifyUserForScreenReplay, initScreenReplay } from "~/services/screen-replay-service";
import { theme } from "~/styles/theme";

initScreenReplay();
const queryClient = new QueryClient();

const App = () => {
  const user = useUser();

  useEffect(() => {
    if (!user.isLoaded) return;

    if (user.isSignedIn) {
      identifyUserForScreenReplay(user.user?.emailAddresses[0].emailAddress);
    }
  }, [user.isSignedIn]);

  return (
    <QueryClientProvider client={queryClient}>
      <Box className="id--app">
        <ChakraProvider theme={theme} resetCSS={true}>
          <Router>
            <Routings />
          </Router>
        </ChakraProvider>
      </Box>
    </QueryClientProvider>
  );
};

export default App;
