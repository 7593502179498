import { Box, Button, Grid, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { ThemeColors } from "~/styles/theme";

const Icon404 = ({
  size = 200,
  backgroundColor = "#f3f4f6",
  paperColor = "white",
  strokeColor = ThemeColors.brand.primaryValue,
  textColor = "#6b7280"
}) => {
  return (
    <div className="inline-block">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        width={size}
        height={size}
        className="w-full h-full"
      >
        {/* Background */}
        <circle cx="100" cy="100" r="90" fill={backgroundColor} />

        {/* Paper with fold */}
        <path d="M50 40 L150 40 L150 160 L50 160 Z" fill={paperColor} stroke={strokeColor} strokeWidth="4" />
        <path d="M130 40 L150 40 L130 60 Z" fill="#e5e7eb" />
        <path d="M130 40 L150 40 L130 60 Z" fill="none" stroke={strokeColor} strokeWidth="4" />

        {/* Sad face */}
        <g transform="translate(75 80)">
          {/* Eyes */}
          <circle cx="15" cy="0" r="5" fill={strokeColor} />
          <circle cx="35" cy="0" r="5" fill={strokeColor} />

          {/* Frown */}
          <path d="M10 25 Q25 15 40 25" fill="none" stroke={strokeColor} strokeWidth="4" strokeLinecap="round" />
        </g>

        {/* 404 text */}
        <text
          x="100"
          y="135"
          fontFamily="Arial, sans-serif"
          fontSize="24"
          fontWeight="bold"
          fill={textColor}
          textAnchor="middle"
        >
          404
        </text>
      </svg>
    </div>
  );
};

const Page404 = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => navigate("/");

  const handleUIErrorTest = () => {
    if (confirm("Are you sure you want to test UI error logging?")) {
      throw new Error("Test error from UI");
    }
  };

  const handleAPIErrorTest = () => {
    if (confirm("Are you sure you want to test API error logging?")) {
      fetch("/api/test-error").catch((error) => {
        console.error("API error test failed", error);
      });
    }
  };

  return (
    <Grid gap={4} textAlign="center" p="10">
      <Heading>
        Page n<span onDoubleClick={handleUIErrorTest}>o</span>t F<span onDoubleClick={handleAPIErrorTest}>o</span>und
      </Heading>

      <Box maxWidth={[280, 400]} marginX="auto">
        <Icon404 />
      </Box>

      <Box>
        <Button variant="secondary" onClick={handleBackToHome}>
          Go back home
        </Button>
      </Box>
    </Grid>
  );
};

export default Page404;
