import { Text, Flex, Heading, VStack, Box } from "@chakra-ui/react";
import { Select } from "chakra-react-select";

import { AvDatePicker } from "~/components/AvDatePicker";
import Meta from "~/layout/Meta";

const DemoItem = ({ label, children }: { label: string; children: React.ReactNode }) => {
  return (
    <Box>
      <Text fontSize="md" fontWeight="bold">
        {label}
      </Text>
      {children}
    </Box>
  );
};

const DemoPage = () => {
  return (
    <>
      <Meta title="Demo Page" />
      <Flex align={["top", "top"]}>
        <VStack align="flex-start">
          <Heading as="h1">Demo Page</Heading>
          <VStack spacing={4} pt={4} align="flex-start">
            <DemoItem label="Date Picker">
              <AvDatePicker selectedDate={new Date()} onChange={() => {}} />
            </DemoItem>
            <DemoItem label="Select">
              <Select
                options={[
                  {
                    label: "I am red",
                    value: "i-am-red",
                    colorScheme: "red"
                  },
                  {
                    label: "I am blue",
                    value: "i-am-blue",
                    colorScheme: "blue"
                  },
                  {
                    label: "I fallback to purple",
                    value: "i-am-purple"
                  }
                ]}
              />
            </DemoItem>
          </VStack>
        </VStack>
      </Flex>
    </>
  );
};

export default DemoPage;
