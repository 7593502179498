import LogRocket from "logrocket";

import { AppConfig } from "~/services/app-config-service";

export function initScreenReplay() {
  if (AppConfig.isLocal) return;
  LogRocket.init(import.meta.env.VITE_LOGROCKET_APP_ID);
}

export function identifyUserForScreenReplay(userId: string) {
  if (AppConfig.isLocal) return;
  LogRocket.identify(userId, {
    email: userId
  });
}
