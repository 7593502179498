import { Heading, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { useAppState } from "~/hooks/useAppState";
import Meta from "~/layout/Meta";

const HomePage = () => {
  const { userInfo } = useAppState("userInfo");

  const fetchTestData = async () => {
    const response = await fetch("/api/me");
    const data = await response.json();
    return data;
  };

  const testData = useQuery({
    queryKey: ["test"],
    queryFn: fetchTestData
  });

  return (
    <>
      <Meta title="Home" />
      <VStack w="full" h="100%" alignItems="stretch" overflow={"hidden"}>
        <Heading>Welcome, {userInfo?.emailAddress}</Heading>
        <Heading size="md">Demo Data Fetching:</Heading>
        <pre>{JSON.stringify(testData.data, null, 2)}</pre>
      </VStack>
    </>
  );
};

export default HomePage;
